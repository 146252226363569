<template>
  <div>
    <div class="header-reference-slider">Haz click en cualquier equipo</div>
    <div class="carousel-groups">
      <BCarousel
        :key="updateSlider"
        :autoplay="false"
        :pause-info="false"
        :arrow="false"
        :indicator-inside="false"
        @change="changeItem"
        v-model="categorySelected"
      >
        <template v-for="(dataGroups, index) in data">
          <BCarouselItem :key="index">
            <template v-for="(group, indexGS) in dataGroups">
              <div :key="indexGS" class="cont-table-mundial-slider">
                <div class="carousel-categories">
                  <section class="categories-container-mini">
                    <div
                      class="arrow-left-slide-informador"
                      @click="changeItemSlideInformador(index - 1 < 0 ? data.length - 1 : index - 1)"
                    >
                      <i class="mdi mdi-chevron-left mdi-24px"></i>
                    </div>
                    {{ group.name }}
                    <div
                      class="arrow-right-slide-informador"
                      @click="changeItemSlideInformador(index + 1 >= data.length ? 0 : index + 1)"
                    >
                      <i class="mdi mdi-chevron-right mdi-24px"></i>
                    </div>
                  </section>
                </div>
                <TableMundialGroupInformador :data="group" :header="false" :teamselect="teamselect" :season="season" />
              </div>
            </template>
          </BCarouselItem>
        </template>
      </BCarousel>
    </div>
  </div>
</template>
<script>
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  name: 'TableMundialSliderInformador',
  components: {
    BCarousel,
    BCarouselItem,
    TableMundialGroupInformador: () => import('@/components/Sections/Table/TableMundialGroupInformador'),
  },
  props: {
    teamselect: { type: Number, default: 0 },
    select: {
      type: Number,
      default: 0,
    },
    data: {
      type: Array,
      default: () => [],
    },
    season: {
      type: [Number, String],
      default: 0,
    },
  },
  watch: {
    select: {
      immediate: true,
      handler: function(newValue) {
        this.categorySelected = newValue;
      },
    },
  },
  data() {
    return {
      updateSlider: 0,
      categorySelected: 0,
    };
  },
  async created() {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {
    changeItemSlideInformador(index) {
      this.categorySelected = index;
    },
    changeItemCategory(index) {
      this.categorySelected = index;
    },
    changeItem(index) {
      this.categorySelected = index;
    },
  },
};
</script>
<style lang="scss">
.arrow-left-slide-informador {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #f2f4f9;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 3px;
  left: 4px;
  top: 3px;
}
.arrow-left-slide-informador:hover {
  cursor: pointer;
  opacity: 0.8;
}
.arrow-right-slide-informador {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #f2f4f9;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding-top: 3px;
  right: 4px;
  top: 3px;
}
.arrow-right-slide-informador {
  cursor: pointer;
  opacity: 0.8;
}
.cont-table-mundial-slider {
  width: 96%;
  height: auto;
  display: inline-block;
  margin: 0 auto;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom: solid 1px #e9ebf0;
  border-right: solid 1px #e9ebf0;
  border-left: solid 1px #e9ebf0;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
}
.header-reference-slider {
  width: 100%;
  height: 18px;
  margin-top: 7px;
  margin-bottom: 6px;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: -0.25px;
  text-align: center;
  color: #132839;
  font-family: 'Circular-Std-Medium';
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.item-category-modal {
  width: 97%;
  height: 26px;
  line-height: 26px;
  font-family: 'Avenir-Demi-Medium';
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.28px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #132739;
  margin-top: 1px;
  margin-bottom: 1px;
}
.item-category-modal:hover {
  background: rgba(207, 207, 207, 0.7);
  cursor: pointer;
}
.modal-categories {
  width: 76%;
  height: 224px;
  position: absolute;
  z-index: 1;
  top: 41px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 10px 20px 15px;
  border-radius: 12px;
  box-shadow: 0 24px 38px 0 rgba(36, 60, 87, 0.25);
  background-color: #fff;
  &__container {
    overflow: auto;
    height: 188px;
  }
}
.carousel-categories {
  width: 100%;
  height: 28px;
  background: #ffffff;
  position: relative;
  // display: inline-block;
  margin: 0 auto;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: solid 1px #e9ebf0;
  border-right: solid 1px #e9ebf0;
  border-left: solid 1px #e9ebf0;
}
.categories-container-mini {
  width: 100%;
  line-height: 28px;
  position: relative;
  margin: 0 auto;
  font-family: 'Avenir-Pro-Bold';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 28px;
  // border: solid 1px #e9ebf0;
  // border-bottom-right-radius: 4px;
  // border-bottom-left-radius: 4px;
  letter-spacing: -0.36px;
  text-align: center;
  color: #132839;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 5px;
  padding-right: 5px;
}

.carousel-categories .carousel-indicator {
  display: none;
}
.carousel-categories .carousel-arrow .icon.has-icons-right {
  right: 1% !important;
}
.carousel-categories .carousel-arrow .icon.has-icons-left {
  left: 1% !important;
}
.carousel-categories .carousel-arrow.is-hovered {
  opacity: 1 !important;
}
.carousel-categories .carousel-arrow .icon {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #f2f4f9;
}
.carousel-categories .carousel-arrow .icon:hover {
  border: 1px solid #f2f4f9;
}
.carousel-categories .carousel-arrow .icon {
  color: rgb(7, 7, 7);
}
.carousel-groups .carousel .carousel-indicator .indicator-item .indicator-style {
  background: #e0e0e0;
  border: none;
  width: 7px !important;
  height: 7px !important;
}
.carousel-groups .carousel .carousel-indicator {
  height: 0px !important;
  margin-top: -5px !important;
}
.carousel-groups .carousel .carousel-indicator .indicator-item.is-active .indicator-style,
.carousel .carousel-indicator .indicator-item .indicator-style:hover {
  background: #cbee6b;
}
</style>
